import React from 'react';

import Layout from '../components/Layout';
import SEO from "../components/Seo"
import pic from '../assets/images/15.jpg';

const IndexPage = () => (
  <Layout>
  <SEO title="Still Life" description="Homecoming isn't the same without her even when memories fade." />
    <div id="main">
    <div className="inner">
    <header>
    <div className="resumeTitle">
  <h1>Interwoven — Chapter 1: Still Life snippet</h1><h4>1,046 words | Slice-of-Life </h4>
  </div>
    </header>
      <section>
        <div className="box alt">
						<div className="row gtr-uniform">
							<div className="col-12"><span className="image fit"><img src={pic} alt="" /></span></div>
						</div>
          </div>
        <div className="storyText">
      <p>“You would’ve fared better with more layers. I have the mind for this sort of thing, you know? One day, you’ll take notice.”</p>
      <p>Perhaps it was chillier than most days. Piles of white powder concealed those craggy roads I came to know as the fastest shortcut to the Crafter’s Square. How snow passed over mountains that dwarfed a sprawling metropolis remained a mystery to me. I was just glad I was farther out than the bombs could reach.</p>
      <p>“Are you listening, dear?” Ms. Pearaly placed a wrinkled palm on my shoulder.</p>
      <p>The blended fabrics— rough linen layered on synthetic suede —were less a treat for her than they were for me. It was the ‘fuzz’ that perturbed her. The touch of tiny hairs— be they soft and smooth or dense like hog bristles —always sent visible shivers down her spine.</p>
      <p>Arching her back and sticking out her touch, Ms. Pearaly took the time to remind me of her displeasure, “Off on your own for two months and this is the type of thing you come back with. We’ll run you through the shops after this. The Peacekeep will never let me hear the end of it. A boy of mine forced to suffer the cold without proper dress…”</p>
      <p>There was much that was better left unsaid. Since accepting a room in Scholar’s District, I had thrifted every fabric that was forbidden to own, eaten every fruit deemed foul for its fuzzy outer flesh, and even went as far as to purchase a modest rug for my semi-private quarter. Ms. Pearaly wouldn’t be pleased if she were to visit. But she’d also never brave ten flights of stairs to visit my newest living space. For her health, it was better she didn’t know.</p>
      <p>“Good deals on pieces like these,” I said, “Grab bags are what they call them. One purchases a whole collection without knowing what it will contain. I fear I’ve bought many out of the thrill alone.”</p>
      <p>I nodded out of politeness. Truthfully, I knew all about grab bags. I knew so much, I stepped into the market blindfolded and arrived at the steps of a wheel cart stacked high with five rows of shelving. The scent, in part, would be my guard. Serentier Senior and the Missus hung and set ablaze oiled pinecones that either drew in or perturbed anyone with much memory of the Outerwilds. It was hard to say how I felt about the scent other than the fact that my head grew dizzy from huffing it in for too long.</p>
      <p>Still, Ms. Pearlaly's excitement wasn't something I'd miss for anything. No matter how many times we came to the market to purchase a grab bag — back when the scars that stretched from my ears to my cheeks were still fresh wounds, when the checkpoint to the Outerwilds hadn't yet been sealed, and just before I took the Peacekeep up on their offer and attended the academy — she always treated it like our first time. And I, of course, treated it as mine as well.</p>
      <p>"What <i>is</i> in a grab bag, Ms. Pearaly? Is there food?"</p>
      <p>She poked my ribs and said, "You'd hope so, wouldn't you? We'll have to put some meat on those bones."</p>
      <p>It was only the second time I heard that phrase — the last being the night of the Endsfeast. With the gates sealed off and under the watch of the Peacekeep, one needed not check over their shoulder to enjoy a meal nor test its taste for poison. That didn't stop me from falling back into old habits, however.</p>
      <p>Leading the charge to the Serentier stall, Ms. Pearaly held her ration notes high. When the merchant's spotted her, they flashed a grin even wider.</p>
      <p>"It'll be two for me," Ms. Pearaly said, "And two for the boy. With the way I'm spoiling Enu, he'll be right in line to apply to the Tax Ministry. Not a job too cushy for him there."</p>
      <p>Fiddling with the collar of my tunic, I gave about as much of a response as I ever would with a slight nod of my head. The Serentiers laughed all the same. Regardless of what I had said or done, they would've done so all the same — my caretaker was their most loyal customer, after all.</p>
      <p>"Something you're celebrating, Ms. Pearaly? Shouldn't be graduation season just yet, shouldn't it?" the Missus asked.</p>
      <p>"Mm," my dear caretaker paused, "I'd have to consult the calendar. But he's home again regardless! That's a reason to celebrate, isn't it?"</p>
      <p>Despite doing my best to hold back, a ruddy hue crept over my cheeks as my teeth broke free from my lips. If I didn't have a large gap from where my bottom left incisor to my bottom left molar was supposed to be, I might have smiled more often. As it stood, I used my hand as a shield — brushing the loose brown strands that just reached the tip of my nose from my face.</p>
      <p>"Good money at the Ministry," Serentier Senior said as he turned to me, "With ration notes like those you'd-"</p>
      <p>Ms. Pearaly cut in, "He's already promised me! Once he's making ration notes of his own, he'll be taking me to the market to buy grab bags daily."</p>
      <p>I swiftly nodded — hoping that'd suffice for a response. And as the Serentiers were busy licking their chops, it turned out it was. Even they knew it was no jest. Even if ordered by the Peacekeep, Ms. Pearaly treated me just like a child of her own. Seeing as I had never known a love more docile and doting, I saw no reason to not offer some back in return.</p>
      <p>With the bag of bobbles placed firmly in her hands, my caretaker whisked her free arm under my elbow and pointed to the docks. The pattern always repeated in exactly the same way. I could've closed my eyes and imagined every step as we followed the winding market road down three shallow platforms and trailed along a gated-off river. The water remained ever-still — not flowing to or from anywhere and sitting exactly where the Zone Commission desired.</p>
      <p>Before she said it, I prepared a nod in anticipation, "Oh! That bench over there! The view is just lovely."</p>
        </div>
      </section>
    </div>
    </div>
  </Layout>
);

export default IndexPage;
